import React from 'react';
import styles from './index.module.scss';
import { ICopyBlock } from './store';

const SocialContent = React.memo(({ socialsText, socialIcons }: ICopyBlock) => {
  return socialIcons ? (
    <div className={styles.socialsContainer}>
      <div
        className={styles.socialText}
        dangerouslySetInnerHTML={{ __html: socialsText as string }}
      />
      <div className={styles.socialIcons}>
        {socialIcons?.map(({ link, image }, index) => (
          <a key={index} href={link} target="_blank" rel="noreferrer">
            <img src={image} alt={link} />
          </a>
        ))}
      </div>
    </div>
  ) : null;
});

export default SocialContent;
