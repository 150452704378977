import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { ICopyBlock } from './store';
import { Button, IconEnum } from '~/common/components/ui-elements';
import { SvgIcon } from '~/common/components/ui-elements/svg-icon';
import cn from 'classnames';
import { toCamelCase } from '~/common/utils';
import SocialContent from './socials-content';

export const CopyBlock = React.memo(
  ({
    showCopyBlock,
    contentHeader,
    isNarrow,
    contentBody,
    contentBackgroundColor,
    fontColor,
    contentCTAs,
    ctaAlignment,
    contentPadding,
    socialsText,
    socialIcons,
  }: ICopyBlock) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.matchMedia('(max-width: 768px)').matches);
      };
      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const _isNarrow = isMobile ? false : isNarrow;
    const _Width = _isNarrow ? '70%' : '90%';

    const getContentPadding = (contentPaddingVal?: string) => {
      if (!!contentPaddingVal) {
        switch (contentPaddingVal?.toLocaleLowerCase()) {
          case 'top':
            return '80px 0px 0px 0px';
          case 'left':
            return '0px 0px 0px 80px';
          case 'right':
            return '0px 80px 0px 0px';
          case 'bottom':
            return '0px 0px 80px 0px';
          default:
            return '0px 0px 0px 0px';
        }
      } else {
        return '0px 0px 0px 0px';
      }
    };

    return (
      <>
        {!!showCopyBlock && (
          <div
            className={styles.CopyBlock_OuterContainer}
            style={{
              ...(contentBackgroundColor
                ? {
                    background: `#${contentBackgroundColor}`,
                  }
                : {}),
            }}
            ref={(element) => {
              if (element && fontColor) {
                element.style.setProperty(
                  'color',
                  `#${fontColor}`,
                  'important'
                );
              }
            }}
          >
            <div
              style={{
                width: _Width || '90%',
                padding: getContentPadding(contentPadding),
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: contentHeader as string,
                }}
              />
              <SocialContent
                socialsText={socialsText}
                socialIcons={socialIcons}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: contentBody as string,
                }}
              />
              <div
                className={styles.CopyBlock_CTAContainer}
                style={{
                  justifyContent: ctaAlignment ? ctaAlignment : 'center',
                }}
              >
                {contentCTAs?.map((cta, idx) => {
                  const buttonProps: any = {
                    type: toCamelCase(cta.ctaButtonType),
                    href: cta.ctaUrl?.url,
                    target: cta.ctaUrl?.target ?? '_self',
                    onClick: () => {},
                  };
                  return (
                    <Button
                      key={idx}
                      className={cn(
                        styles.CTA,
                        styles[`CTA${buttonProps.type}`]
                      )}
                      style={{
                        padding: socialIcons ? '8px 100px' : 'auto',
                      }}
                      {...buttonProps}
                    >
                      {cta.ctaIcon && cta.ctaIcon !== 'none' && (
                        <SvgIcon
                          className={styles.CTAIcon}
                          type={cta.ctaIcon as keyof typeof IconEnum}
                          color="#1637a0"
                          size={1.2}
                          strokeWidth={2}
                        />
                      )}
                      <span
                        className={styles.CTAText}
                        style={
                          buttonProps.type === 'text'
                            ? { color: '#1637a0' }
                            : {}
                        }
                      >
                        {cta.ctaUrl?.name || ''}
                      </span>
                    </Button>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);
